@import './global/_variables';
@import 'bootstrap-sass/assets/stylesheets/bootstrap';
@import './spark/spark';

@import 'select2-bootstrap-theme/src/select2-bootstrap';
@import 'datatables.net-bs/css/dataTables.bootstrap';
@import 'datatables.net-buttons-bs/css/buttons.bootstrap';

@import './pages/login';
@import './global/components/elements/steps/step-1';
@import './admin/admin';
@import './pages/reports';
@import './datatables';
@import './global/plugins/summernote';

/**
 * APP WIDE CSS
 * See app/frontend/assets/sass/layouts/layout/_custom.scss to override LAYOUT
 */

// Vue Cloak
[v-cloak] {
  display: none;
}

#spark-app {
  // Initial boot loader
  #boot-loader {
    position: relative;
    height:100vh;
    width:100vw;
    background: rgba(255,255,255,0.7);
    z-index: 65534;

    .loading {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 3em;
    }
  }
}

.breadcrumb {
  .last {
    font-weight: bold;
  }
}

#settings-view {
  display: none;

  &.active {
    display: block;
  }
}

.auth.login {
  .page-footer {
    margin: 0 auto;
    width: 500px;

    .copyright-footer, .name-long {
      text-align: center;
      width: 100%;
    }
  }
}

.loading-message {
  text-transform: uppercase;
}

.importFile, #uploadPricesList,
.importFile, #uploadMapPolicy {
  display: none !important;
}

.modal-large {
  .modal-dialog {
    height: 50vh;
    width: 75%;

    & > .modal-content {
      .modal-body {
        height: 75%;
      }
    }
  }
}

.counter-badge {
  font-size: 10px;
  float: right;
  border-radius: 5px;
}

.page-footer {
  .name-long {
    text-align: right;
  }
}

.dt-channel-select label {
  width: 100%;
}

#dt-channel-select {
  width: auto;
  display: inline-block;
  min-width: 25%;
  width: 35%;
}


/* Styles for DataTables Multi Select */

// Fix for menu items color in selected rows:
  table.dataTable tr.selected td.sorting_1,
  table.dataTable tr.selected td.sorting_2,
  table.dataTable tr.selected td.sorting_3,
  table.dataTable tr.selected th.sorting_1,
  table.dataTable tr.selected th.sorting_2,
  table.dataTable tr.selected th.sorting_3 {
     // background-color: inherit !important;
  }
  table.dataTable tbody tr.selected ul a,
  table.dataTable tbody th.selected ul a,
  table.dataTable tbody td.selected ul a {
      color: #6f6f6f;
  }
  table.dataTable tr.selected {
    background-color: initial !important;
  }
  table.dataTable tr.selected td {
    background-color: #0088cc !important;
  }


// Styles for the checkbox that selects all rows:
  table.dataTable thead th.select-checkbox {
      position: relative !important;
  }
  table.dataTable thead th.select-checkbox:after {
      content: ' ';
      display: block;
      width: 12px;
      height: 12px;
      bottom: 15px;
      position: absolute;
      left: 35%;
      margin-top: -6px;
      margin-left: 1px;
      color: black;
  }
  table.dataTable thead th.select-checkbox[data-select-status="page"]:after,
  table.dataTable thead th.select-checkbox[data-select-status="all"]:after {
      content: '\2714' !important;
  }
  table.dataTable thead th.select-checkbox[data-select-status="some"]:after {
      content: '-';
      margin-left: 3px;
  }
  table.dataTable thead th.select-checkbox:before {
      content: ' ';
      border: 1px solid black;
      border-radius: 3px;
      display: block;
      width: 12px;
      height: 12px;
      bottom: 10px;
      position: absolute;
      left: 35%;
  }

/* End of styles for DataTables Multi Select */

.highlight-yellow {
  background-color: #fff688;
}

.highlight-blue {
  background-color: #d5f4e6;
}

.slide-enter-active, .slide-leave-active {
  transition: all .5s
}
.slide-enter {
  height: 0; opacity: 0;
}
.slide-leave-to {
  opacity: 0;
}

/* Responsive data table */

.table-responsive {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  min-height: 380px;
}

div.dataTables_wrapper div.dataTables_info {
  padding-bottom: 40px;
}

div.dataTables_wrapper div.dataTables_paginate ul.pagination {
  padding-bottom: 10px;
}

/* Vue-select custom styles */

.search-select-title {
  margin-top: 23px;
}

.search-select-product {
  margin-top: 15px;
  margin-bottom: 30px;
}

.v-select {
  .dropdown-menu {
    max-height: 230px !important;
    overflow-x: hidden;
  }
  .dropdown-toggle {
    height: 36px;
  }
  .selected-tag {
    white-space: nowrap;
    margin-right: 50px !important;
  }
}

.scroller {
  overflow: visible !important;
}

/* Syntax highlighting for JSON */

pre {
  outline: none;
  padding: 5px;
}

.string {
  color: green;
}

.number {
  color: darkorange;
}

.boolean {
  color: blue;
}

.null {
  color: magenta;
}

.key {
  color: red;
}


/* Styles for notes */

.note-form-control {
  border: 1px solid #c2cad8 !important;
  overflow-x: hidden !important;
  padding: 10px !important;
}

.img-opacity {
  opacity: 0.5;
  filter: alpha(opacity=50);
}

/* Styles for search strings */

.form-control-string-create {
  font-size: 18px;
  height: 50px;
  border: 2px solid #c2cad8;
}

.form-control-string-edit {
  font-size: 18px;
  margin-top: 2px;
}

.btn-string-action {
  margin-top: 2px !important;
}

.table-component__filter__clear {
  visibility: hidden;
}

.table-short {
  .md-checkbox label > .box {
    top: 4px;
  }
  .md-checkbox label > .box-table-footer {
    top: 0;
  }
  .md-checkbox label > .check {
    top: 1px;
  }
  .md-checkbox label > .check-table-footer {
    top: -4px;
  }
  .table tr,
  .table td {
    padding-bottom: 0;
    padding-top: 0;
    vertical-align: middle;
    line-height: 1.3;
  }
  .pagination {
    margin-top: -20px;
  }
}

.table-short-title {
  margin-top: 0;
}

.table > tfoot > tr > th,
.table > tfoot > tr > td {
  padding: 8px !important;
}

/* 5 columns grid for md-min screens */

@media (min-width: $screen-md-min) { /* 992px */
  .col-md-1-5 {
    width: 20%;
  }
  .col-md-2-5 {
    width: 40%;
  }
  .col-md-3-5 {
    width: 60%;
  }
  .col-md-4-5 {
    width: 80%;
  }
  .col-md-5-5 {
    width: 100%;
  }
}

/* Image tooltip */

.image-tooltip + .tooltip > .tooltip-inner {
  max-width: 260px;
  padding: 5px;
}

/* Entity Browser */
.mr-auto {
    margin-right: auto;
}

.link {
    color: #00008b;
    cursor: pointer;
}

.pointer {
    cursor: pointer;
}

.white .toast {
    background-color: rgba(255,255,255,.85);
}
.toast {
    min-width: 350px;
    overflow: hidden;
    font-size: 1;
    background-color: rgba(255,255,255,.85);
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.1);
    box-shadow: 0 0.25rem 0.75rem rgba(0,0,0,.1);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border-radius: .25rem;
}
.toast-header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    padding: .25rem .75rem;
    color: #6c757d;
    background-color: rgba(255,255,255,.85);
    background-clip: padding-box;
    border-bottom: 1px solid rgba(0,0,0,.05);
}
.toast-body {
    padding: .75rem;
}
.toast .table-striped tr:nth-of-type(odd) {
    background-color: rgba(0,0,0,.05);
}

/* MAPSentry buttons that inverse colors on hover */
.hover-inverse-red,
.hover-inverse-red a { color: rgb(231, 80, 90); }
.hover-inverse-red:hover { color: white; background-color: rgb(231, 80, 90); }
.hover-inverse-red:hover a { text-decoration: none; color: white; }
.hover-inverse-red:hover a:hover { text-decoration: none; color: white; }

.hover-inverse-blue,
.hover-inverse-blue a { color: rgb(53, 152, 220); }
.hover-inverse-blue:hover { color: white; background-color: rgb(53, 152, 220); }
.hover-inverse-blue:hover a { text-decoration: none; color: white; }
.hover-inverse-blue:hover a:hover { text-decoration: none; color: white; }

.hover-inverse-cyan,
.hover-inverse-cyan a { color: rgb(54, 198, 211); }
.hover-inverse-cyan:hover { color: white; background-color: rgb(54, 198, 211); }
.hover-inverse-cyan:hover a { text-decoration: none; color: white; }
.hover-inverse-cyan:hover a:hover { text-decoration: none; color: white; }

.hover-inverse-yellow,
.hover-inverse-yellow a { color: rgb(196, 159, 71); }
.hover-inverse-yellow:hover { color: white; background-color: rgb(241, 196, 15); }
.hover-inverse-yellow:hover a { text-decoration: none; color: white; }
.hover-inverse-yellow:hover a:hover { text-decoration: none; color: white; }

.hover-inverse-purple,
.hover-inverse-purple a { color: rgb(176, 129, 711); }
.hover-inverse-purple:hover { color: white; background-color: rgb(196, 159, 711); }
.hover-inverse-purple:hover a { text-decoration: none; color: white; }
.hover-inverse-purple:hover a:hover { text-decoration: none; color: white; }


.scraping-status {
    width: 100%; margin: 5px 30px;
}
.scraping-status th {
    /* text-align: center; */
    font-weight: 200;
 }
.scraping-status .values td:nth-child(odd) {
    text-align: right;
    padding: 0 3px;
}

.scraping-status .red { color: rgb(231, 80, 90); }
.scraping-status .yellow { color: rgb(196, 159, 71); }
.scraping-status .purple { color: rgb(176, 129, 711); }
.scraping-status .blue { color: rgb(53, 152, 220); }

.scraping-status .big    { font-size: 2em; }
.scraping-status .medium  { font-size: 1.5em; }
.scraping-status .small  { font-size: 0.9em; }

.scraping-status.sans,
.scraping-status .sans  { font-family: "Open Sans",sans-serif; }
