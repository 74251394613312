@import './priority-queue';

.kiosk-row {
}


.kiosk-panel {

  .nav > li > a {
    text-align: center;

    & span {
      display: none;
    }
  }

  &:hover {

    .nav > li > a {
      text-align: left;

      & span {
        display: inline;
      }
    }
  }
}

.kiosk-panel:hover + .kiosk-main {
  width: 83.33333%;
}

.kiosk-main {
  width: 93.33333%;
}

.kiosk-panel, .kiosk-panel:hover {
width: 100%;

.nav > li {
    display: inline-block;
}
.nav > li.active {
    font-weight: bold;
}
.nav > li > a {
    text-align: center;
}
.nav > li > a span{
    display: inline-block;
}
}

.kiosk-main,
.kiosk-panel:hover + .kiosk-main {
width: 100%;
}

