.spark-screen {
  form h2 {
    background-color: $panel-default-heading-bg;
    border-radius: $border-radius-base;
    font-size: 14px;
    font-weight: 300;
    margin-top: 0;
    margin-bottom: 15px;
    padding: 12px;
  }
}

.control-label {
  font-weight: 300;
}

.radio label, .checkbox label {
  font-weight: 300;
}
