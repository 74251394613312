@keyframes stepAnimationColor {
  from {
    color: #32c5d2;
    border-color: #32c5d2;
  }
}

@keyframes stepAnimationColorBg {
  from {
    background-color: #32c5d2;
  }
}

.mt-element-step {
  .mt-step-content {
    font-size: 14px;
  }
}

.animate-step-active {
  .mt-step-number {
    animation: stepAnimationColor 1s infinite alternate;
  }

  .mt-step-title,
  .mt-step-title-small,
  .mt-step-content {
    animation: stepAnimationColor 1s infinite alternate;
  }

  .mt-step-title,
  .mt-step-title-small
  {
    &:after,
    &:before {
      background-color: #E5E5E5 !important;
      animation: stepAnimationColorBg 1s infinite alternate;
    }
  }
}

#step-output {
  height: 300px;
  overflow-y: scroll;
}
