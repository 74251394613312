.small-input, .datatable-column-search {
  width: 100%;
}

table.dataTable > tfoot > tr > th {
  padding: 7px 3px;
}

.dataTables_wrapper .dataTables_processing {
  display: none !important;
}

.dataTable .actions-menu.dropdown-menu {
  left: -99px;
}

.dataTable tbody td.dt-pre {
  display: block;
  unicode-bidi: embed;
  font-family: monospace;
  white-space: pre;
  font-size: 10px;
}

table.table-striped.dataTable {
    background-color: #ffffff;
}

.small-input, .datatable-column-select {
  width: 130px;
  background-color: white;
  padding: 2px 0;
  color: #747474;
}