@import './../global/_variables';

div.reports {
  .reports-links {
    height: 150px;
    text-align: center;
    
    a {
      font-size: $font-size-h3;
    }
  }
}
