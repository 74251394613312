// Body
$body-bg: #f5f8fa;

// Base Border Color
$spark-border-color: darken($body-bg, 10%);

// Set Common Borders
$list-group-border: $spark-border-color;
$navbar-default-border: $spark-border-color;
$panel-default-border: $spark-border-color;
$panel-inner-border: $spark-border-color;

// Brands
$brand-primary: #3097D1;
$brand-info: #8eb4cb;
$brand-success: #4eb76e;
$brand-warning: #cbb956;
$brand-danger:  #bf5329;

// Typography
$font-family-sans-serif: "Open Sans", Helvetica, Arial, sans-serif;
$line-height-base: 1.6;
$text-color: #636b6f;

// Buttons
$btn-default-color: $text-color;
$btn-font-size: $font-size-base;
$btn-font-weight: 300;

// Inputs
$input-border: lighten($text-color, 40%);
$input-border-focus: lighten($brand-primary, 25%);
$input-color-placeholder: lighten($text-color, 30%);

// Navbar
$navbar-height: 50px;
$navbar-margin-bottom: 0;

$navbar-inverse-bg: #fff;
$navbar-inverse-color:  lighten($text-color, 30%);
$navbar-inverse-border: $spark-border-color;

$navbar-inverse-link-color: lighten($text-color, 25%);
$navbar-inverse-link-active-bg: transparent;
$navbar-inverse-link-active-color: $navbar-inverse-link-hover-color;
$navbar-inverse-link-hover-color: darken($navbar-inverse-link-color, 5%);

$navbar-inverse-toggle-border-color: $spark-border-color;
$navbar-inverse-toggle-hover-bg: $navbar-inverse-bg;
$navbar-inverse-toggle-icon-bar-bg: $spark-border-color;

// Dropdowns
$dropdown-anchor-padding: 5px 20px;
$dropdown-border: $spark-border-color;
$dropdown-divider-bg: lighten($spark-border-color, 5%);
$dropdown-header-color: darken($text-color, 10%);
$dropdown-link-color: $text-color;
$dropdown-link-hover-bg: #fff;
$dropdown-padding: 10px 0;
