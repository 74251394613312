.panel {
  overflow: hidden;
}

.panel-heading {
  font-size: 15px;
  font-weight: 400;
}

.panel-body {
  font-weight: 300;
}

.panel-flush {
  .panel-body, .panel-header {
    padding: 0;
  }
}
